import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";

const DetailsCompanyComponent = loadable(() => import("../../components/Company/Details"));

const DetailsCompany = () => {
  const { data: companies, isLoading: companyLoading } = useGetHook({
    queryKey: ["dropdown_company"],
    url: `${APIS.COMPANY}`,
    params: {
    }
  });
  const { data: department, isLoading: departmentLoading } = useGetHook({
    queryKey: ["dropdown_department"],
    url: `${APIS.DEPARTMENT}`,
    params: {
    }
  });
  return (
    <>
      <div>
        <DetailsCompanyComponent
          company={companies}
          department={department}
        />
      </div>
    </>
  );
};

export default DetailsCompany;