import loadable from "@loadable/component";

const AddDepartmentComponent = loadable(() => import("../../components/Staff/staffs"));

const AddDepartment = () => {
  return (
    <>
      <div>
      DetailsDepartment
      </div>
    </>
  );
};

export default AddDepartment;