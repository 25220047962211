const APIS = {
  // Pages
  FILTER_ATTENDANCE: `/attendance/`,
  ATTENDANCE_DETAIL: `/attendance/`,
  ATTENDANCE_DOWNLOAD: `/attendance/download/`,
  MEDIA: `/media/`,
  USERS: `/user/`,
  COMPANY: `/company/?type=company`,
  SINGLE_COMPANY: `/company/`,
  DEPARTMENT: `/company/?type=department`,
  SHIFT: "/company/?type=shift",
  STAFF: "/register-staff/",

  //THEMES
  THEMES: `/theme/`,
  CHANGE_THEME: `/change-theme/`,
  THEME_COMPONENTS: `/theme-templates/`,
};

export default APIS;
