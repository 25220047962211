//@ts-ignore
import Axios from "axios";
import { accessToken } from "./sessionKey";
import { toast } from "react-toastify";

const API_ROOT: string = "https://dibattendanceservice.dibsolutions.com.au/";

export default API_ROOT;
//create axios instance
export const instance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `JWT ${accessToken()}`,
  },
});
instance.interceptors.request.use(
  function (config: any) {
    config.headers = {
      'x-requested-with': '',
      authorization: `JWT ${accessToken()}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    if (response) {
      if (response.status === 404) {
        toast.error('Error');
      } else if (response.status === 401) {
        console.log("status", response)
        window.location.replace("/auth-login")
      } else {
        toast.error(response.data.msg);
      }
    }

    return Promise.reject(error);
  },
);

const USER_MANAGEMENT_API_ROOT: string = "https://usermanagement.dibsolutions.com.au/api";
//create axios instance
export const userManangementInstance = Axios.create({
  baseURL: `${USER_MANAGEMENT_API_ROOT}`,
  headers: {
    "Content-Type": "application/json",
  },
});
userManangementInstance.interceptors.request.use(
  function (config: any) {
    config.headers = {
      'x-requested-with': '',
      authorization: `JWT ${accessToken()}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

userManangementInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    if (response) {
      if (response.status === 404) {
        toast.error('Error');
      } else if (response.status === 401) {
        console.log("status", response)
        window.location.replace("/auth-login")
      } else {
        toast.error(response.data.msg);
      }
    }

    return Promise.reject(error);
  },
);

// create axios instance for form data to upload file
export const formDataInstance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-Type": `multipart/form-data`,
  },
});
formDataInstance.interceptors.request.use(
  function (config: any) {
    config.headers = {
      'x-requested-with': '',
      authorization: `JWT ${accessToken()}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

formDataInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    if (response) {
      if (response.status === 404) {
        toast.error('Error');
      } else if (response.status === 401) {
        console.log("status", response)
        window.location.replace("/auth-login")
      } else {
        toast.error(response.data.msg);
      }
    }

    return Promise.reject(error);
  },
);
// create form data to upload files
export const postApiFormData = async (fileData) => {
  const { url, formData, formikProps } = fileData;
  let response;
  try {
    response = await formDataInstance({
      method: "POST",
      url: `${url}`,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      data: formData,
      transformResponse: [
        function (responseData) {
          //Do what ever you want transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e.response);
    formikProps?.setErrors(e.response.data);
    return e.response;
  }
  return response;
};

//get data from api
export const getApiData = async (url, param = null) => {
  let response;
  try {
    response = await instance({
      method: "GET",
      url: `${url}`,
      params: param,
      // headers: {
      //   Authorization: `JWT ${accessToken()}`,
      // },
      transformResponse: [
        function (responseData) {
          // Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    return e.response;
  }
  return response;
};

export const userManagementGetApiData = async (url, param = null) => {
  let response;
  try {
    response = await userManangementInstance({
      method: "GET",
      url: `${url}`,
      params: param,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          // Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    return e.response;
  }
  return response;
};
//post data to api
export const postApiData = async (data) => {
  const { url, formData } = data;
  let response;
  try {
    response = await instance({
      method: "POST",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    return e.response;
  }
  return response;
};

//post data to api
export const postApiDataNoAuth = async (data) => {
  const { url, formData } = data;
  let response;
  try {
    response = await instance({
      method: "POST",
      url: `${url}`,
      data: formData,
      headers: {
        // Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    return e.response;
  }
  return response;
};

//update data
export const putApiData = async (data) => {
  const { url, formData, formikProps } = data;

  // eslint-disable-next-line no-console
  let response;
  try {
    response = await instance({
      method: "PUT",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    formikProps.setErrors(e.response.data);
    return e.response;
  }
  return response;
};

//delete data
export const deleteApiData = async ({ url }) => {
  let response;
  response = await instance({
    method: "DELETE",
    url: url,
    // params: param,
    headers: {
      Authorization: `JWT ${accessToken()}`,
    },
  });
  return response;
};

//delete bulk data
export const bulkDeleteApiData = async (data) => {
  const { url, formData } = data;
  let response;
  response = await instance({
    method: "DELETE",
    url: `${url}`,
    data: formData,
    // params: param,
    headers: {
      Authorization: `JWT ${accessToken()}`,
    },
  });
  return response;
};

//update data
export const patchApiData = async (data) => {
  const { url, formData } = data;
  let response;
  try {
    response = await instance({
      method: "PATCH",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    // formikProps.setErrors(e.response.data);
    return e.response;
  }
  return response;
};

export const downloadApiDataGET = async (data: any) => {
  const { url, fileName, formData } = data
  let response: any;
  try {
    response = await instance({
      method: "POST",
      url: url,
      data: formData,
      responseType: "blob",
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    return error.response;
  }
  return response;
};
export const useFileDownloadApiData = async (data) => {
  const { url, formData, formikProps } = data;
  let response;
  try {
    response = await Axios.create({
      method: "POST",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
        // "Content-Type": "application/x-zip-compressed",
        "Content-Type": `multipart/form-data`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e) {
    formikProps.setErrors(e.response.data);
    return e.response;
  }
  return response;
};


export { API_ROOT };
