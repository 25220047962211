import loadable from "@loadable/component";

const DetailsStaffComponent = loadable(() => import("../../components/Staff/staffs"));

const DetailsStaff = () => {
  return (
    <>
      <div>
      DetailsStaff
      </div>
    </>
  );
};

export default DetailsStaff;