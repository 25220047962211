import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getApiData, postApiData } from "../helpers/AxiosInstance";
import { toast } from "react-toastify";

interface IUseGetHook {
  queryKey: any;
  url: string;
  params: any;
  enabled?: any;
}

export const useGetHook = (props: IUseGetHook) => {
  const { queryKey, url, params, enabled } = props;
  let response;
  const { isLoading, isError, data, isSuccess, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      response = await getApiData(url, params);
      if (response.status === 404) {
        throw new Error(response.data.message);
      }
      return response.data;
    },

    refetchOnWindowFocus: false,
    enabled,
  });

  return { isLoading, isError, data, isSuccess, refetch };
};

export const usePostHook = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ url, formData }: { url: any; formData: any }) => {
      const response = await postApiData({ url, formData });
      return response.data;
    },
    onSuccess: (data: any) => {
      if (data?.message) {
        toast.success(data?.message);
      } else {
        // toast.success("Operation successful!");
      }
    },
    onError: () => {
      toast.error("Order is not completete");
    },
  });
};
