import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import { useParams } from "react-router-dom";
import usePatchHook from "../../customHooks/usePatchHook";
import useUserManangementGetHook from "../../customHooks/useUserManangementGetHook";

const EditStaffComponent = loadable(() => import("../../components/Staff/EditStaff"));

const EditStaff = () => {
  const { id } = useParams()
  const { isLoading, mutateAsync, data } = usePatchHook({ queryKey: ["staff", `staff-${id}`], navigateURL: "/staffs" })

  const { data: company, isLoading: companyLoading } = useGetHook({
    queryKey: ["company"],
    url: `${APIS.COMPANY}`,
    params: {
      type: 'company'
    }
  });
  const { data: staff, isLoading: staffLoading } = useGetHook({
    queryKey: [`single_staff-${id}`],
    url: `${APIS.SINGLE_COMPANY}${id}/`,
    params: {
      type: 'staff'
    }
  });
  const { data: department, isLoading: departmentLoading } = useGetHook({
    queryKey: ["department"],
    url: `${APIS.DEPARTMENT}`,
    params: {
      type: 'department'
    }
  });
  const { data: shift, isLoading: shiftLoading } = useGetHook({
    queryKey: ["shift"],
    url: `${APIS.SHIFT}`,
    params: {
      type: 'shift'
    }
  });

  const handleUpdate = async (values: any) => {
    const url = `${APIS.STAFF}${id}/`
    const formData = {
      name: values.first_name

    }
    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }
  const { data: user, isLoading: userLoading } = useUserManangementGetHook({
    queryKey: ["user"],
    url: `${APIS.USERS}`,
    params: {
    }
  });
  return (
    <>{staff ?
      <EditStaffComponent
        company={company}
        department={department}
        shift={shift}
        staff={staff}
        handleUpdate={handleUpdate}
        user={user}
      /> : "Loading"
    }
    </>
  );
};

export default EditStaff;