import React from "react";
import loadable from "@loadable/component";

const ReportComponent = loadable(() => import("../../components/Report/reports"));

const Report = () => {
  return (
    <>
      <ReportComponent />
    </>
  );
};

export default Report;