import loadable from "@loadable/component";

const DetailsShiftComponent = loadable(() => import("../../components/Staff/staffs"));

const DetailsShift = () => {
  return (
    <>
      <div>
      DetailsShift
      </div>
    </>
  );
};

export default DetailsShift;