import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";

const AddShiftComponent = loadable(() => import("../../components/Shift/CreateShift"));

const AddShift = () => {
  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["shift"], navigateURL: "/shift" })
  const handleCreateShift = async (values: any, pk: string) => {
    const url = `${APIS.SHIFT}${pk}`
    const formData = {
      name: values.shift_name,
    }
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const { data: company, isLoading: companyLoading } = useGetHook({
    queryKey: ["company"],
    url: `${APIS.COMPANY}`,
    params: {
    }
  });
  const { data: department, isLoading: departmentLoading } = useGetHook({
    queryKey: ["department"],
    url: `${APIS.DEPARTMENT}`,
    params: {
      type: 'department'
    }
  });
  console.log(department)

  return (
    <>
      {companyLoading || departmentLoading ?
        "" :
        <AddShiftComponent
          handleCreateShift={handleCreateShift}
          company={company}
          department={department}
        />
      }
    </>
  );
};

export default AddShift;