import loadable from "@loadable/component";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";
import usePatchHook from "../../customHooks/usePatchHook";
import { useParams } from "react-router-dom";

const EditCompanyComponent = loadable(() => import("../../components/Company/EditCompany"));

const EditCompany = () => {
  const { id } = useParams()
  const { isLoading, mutateAsync, data } = usePatchHook({ queryKey: ["company", `company-${id}`], navigateURL: "/companies" })

  const { data: company, isLoading: companyLoading } = useGetHook({
    queryKey: [`single-company-${id}`],
    url: `${APIS.SINGLE_COMPANY}${id}/`,
    params: {}
  });

  console.log(company, "single company");


  const handleUpdate = async (values: any) => {
    const url = `${APIS.COMPANY}${id}/`
    const formData = {
      name: values.company_title

    }
    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>{company ?
      <EditCompanyComponent
        company={company}
        handleUpdate={handleUpdate}
      /> : "Loading"
    }
    </>
  );
};

export default EditCompany;