import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";

const AddDepartmentComponent = loadable(() => import("../../components/Department/CreateDepartment"));

const AddDepartment = () => {
  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["department"], navigateURL: "/department" })
  const handleCreateDepartment = async (values: any, pk: string) => {
    console.log(values, "test");
    const url = `${APIS.DEPARTMENT}${pk}`;
    const formData = {
      name: values.department_title,
      parent: values.select_company
    }
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
    } catch (err) {
      console.log(err);

    }

  };

  const { data: company, isLoading: companyLoading } = useGetHook({
    queryKey: ["company"],
    url: `${APIS.COMPANY}`,
    params: {
      type: 'company'
    }
  });
  return (
    <>
      {companyLoading ?
        "" :
        <AddDepartmentComponent
          handleCreateDepartment={handleCreateDepartment}
          company={company}
        />
      }
    </>
  );

};

export default AddDepartment;
