import React from "react";
import loadable from "@loadable/component";

const SettingComponent = loadable(() => import("../../components/Setting/Settings"));

const Setting = (props: any) => {
  return (
    <>
      <SettingComponent />
    </>
  );
};

export default Setting;


