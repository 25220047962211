// import { getLocalKey } from "../helpers/sessionKey";

const PrivateRoute = ({ children }) => {
  // const token = getLocalKey("token");
  // if (!token) {
  //   return <Navigate to="/login" />;
  // }
  return children;
};

export default PrivateRoute;
