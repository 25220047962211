import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import usePatchHook from "../../customHooks/usePatchHook";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";

const EditDepartmentComponent = loadable(() => import("../../components/Department/EditDepartment"));

const EditDepartment = () => {
  const { id } = useParams()
  const { isLoading, mutateAsync, data } = usePatchHook({ queryKey: ["department", `department-${id}`], navigateURL: "/department" })

  const { data: department, isLoading: departmentLoading } = useGetHook({
    queryKey: [`single_department-${id}`],
    url: `${APIS.SINGLE_COMPANY}${id}/`,
    params: {}
  });


  const { data: company, isLoading: companyLoading } = useGetHook({
    queryKey: ["company"],
    url: `${APIS.COMPANY}`,
    params: {
      type: 'company'
    }
  });

  const handleUpdate = async (values: any) => {
    const url = `${APIS.DEPARTMENT}${id}/`
    const formData = {
      name: values.category_title

    }
    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>{department ?
      <EditDepartmentComponent
        company={company}
        department={department}
        handleUpdate={handleUpdate}
      /> : "Loading"
    }
    </>
  );
};

export default EditDepartment;