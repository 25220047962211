import React from "react";
import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useDeleteHook from "../../customHooks/useDeleteHook";

const DepartmentComponent = loadable(() => import("../../components/Department/department"));

const Department = () => {
  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["department"] })

  const handleDelete = async (setOpenPopup: any, pk: string,) => {
    const url = `${APIS.DEPARTMENT}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <DepartmentComponent
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Department;