import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import usePatchHook from "../../customHooks/usePatchHook";
import { useParams } from "react-router-dom";

const EditShiftComponent = loadable(() => import("../../components/Shift/EditShift"));

const EditShift = () => {
  const { id } = useParams()
  const { isLoading, mutateAsync, data } = usePatchHook({ queryKey: ["shift", `shift-${id}`], navigateURL: "/shift" })



  const handleUpdate = async (values: any) => {
    const url = `${APIS.SHIFT}${id}/`
    const formData = {
      name: values.shift_name

    }
    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }
  const { data: company, isLoading: companyLoading } = useGetHook({
    queryKey: ["company"],
    url: `${APIS.COMPANY}`,
    params: {
      type: 'company'
    }
  });

  const { data: shift, isLoading: shiftLoading } = useGetHook({
    queryKey: [`single-shift-${id}`],
    url: `${APIS.SINGLE_COMPANY}${id}/`,
    params: {}
  });

  const { data: department, isLoading: departmentLoading } = useGetHook({
    queryKey: ["department"],
    url: `${APIS.DEPARTMENT}`,
    params: {
      type: 'department'
    }
  });
  return (
    <>
      {company && department && shift ?
        <EditShiftComponent
          company={company}
          department={department}
          shift={shift}
          handleUpdate={handleUpdate}
        /> : "Loading"
      }

    </>
  );
};

export default EditShift;