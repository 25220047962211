import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import usePostHook from "../../customHooks/usePostHook";
import useUserManangementGetHook from "../../customHooks/useUserManangementGetHook";
import { useParams } from "react-router-dom";

const AddStaffComponent = loadable(() => import("../../components/Staff/CreateStaff"));

const AddStaff = () => {
  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["staff"], navigateURL: "/staffs" })
  const handleCreateStaff = async (values: any) => {
    const url = APIS.STAFF
    const formData = {
      name: values.first_name,
      attendance_device_terminal: values.terminal_id,
      attendance_device_user_id: values.user_id,
      pin: values.attendence_pin,
      user: values.username
    }
    // console.log(values)
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
    } catch (err) {
      console.log(err);

    }

  };
  const { data: company, isLoading: companyLoading } = useGetHook({
    queryKey: ["company"],
    url: `${APIS.COMPANY}`,
    params: {
      type: 'company'
    }
  });
  const { data: department, isLoading: departmentLoading } = useGetHook({
    queryKey: ["department"],
    url: `${APIS.DEPARTMENT}`,
    params: {
      type: 'department'
    }
  });
  const { data: shift, isLoading: shiftLoading } = useGetHook({
    queryKey: ["shift"],
    url: `${APIS.SHIFT}`,
    params: {
      type: 'shift'
    }
  });
  const { data: user, isLoading: userLoading } = useUserManangementGetHook({
    queryKey: ["user"],
    url: `${APIS.USERS}`,
    params: {
    }
  });
  return (
    <>
      {companyLoading || departmentLoading ?
        "" :
        <AddStaffComponent
          handleCreateStaff={handleCreateStaff}
          company={company}
          department={department}
          shift={shift}
          user={user}
        />
      }
    </>
  );
};

export default AddStaff;