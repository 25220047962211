import { useQuery } from "@tanstack/react-query";
import { getApiData } from "../helpers/AxiosInstance";

const useGetHook = ({ queryKey, url, params }) => {
  const { isLoading, isError, data, isSuccess } = useQuery(
    queryKey,
    async () => {
      const response = await getApiData(url, params);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );

  return { isLoading, isError, data, isSuccess };
};

export default useGetHook;
