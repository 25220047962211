import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../contants/EndPoints";

const AddCompanyComponent = loadable(() => import("../../components/Company/CreateCompany"));

const AddCompany = () => {
  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["company"], navigateURL: "/companies" })
  const handleCreateCompany = async (values: any) => {
    const url = APIS.COMPANY
    const formData = {
      name: values.company_title
    }
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
    } catch (err) {
      console.log(err);

    }
  };

  return (
    <>
      <div>
        <AddCompanyComponent
          handleCreateCompany={handleCreateCompany}
        />
      </div>
    </>
  );

}
export default AddCompany;