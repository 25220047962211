import React from "react";
import loadable from "@loadable/component";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";
import useDeleteHook from "../../customHooks/useDeleteHook";

const StaffComponent = loadable(() => import("../../components/Staff/staffs"));

const Staff = () => {
  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["staff"] })

  const handleDelete = async (setOpenPopup: any, pk: string,) => {
    const url = `${APIS.STAFF}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <StaffComponent
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Staff;