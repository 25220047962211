import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Company from "../pages/Company/Company";
import Setting from "../pages/Setting";
import AttendaceReport from "../pages/Attendance/AttendanceReport";
import Department from "../pages/Department/Department";
import Shift from "../pages/Shift/Shift";
import Staff from "../pages/Staff/Staff";
import Report from "../pages/Report/Report";
import AddShift from "../pages/Shift/AddShift";
import AddCompany from "../pages/Company/AddCompany";
import AddStaff from "../pages/Staff/AddStaff";
import AddDepartment from "../pages/Department/AddDepartment";
import EditDepartment from "../pages/Department/EditDepartment";
import DetailsDepartment from "../pages/Department/DetailsDepartment";
import DetailsCompany from "../pages/Company/DetailsCompany";
import EditCompany from "../pages/Company/EditCompany";
import DetailsStaff from "../pages/Staff/DetailsStaff";
import EditStaff from "../pages/Staff/EditStaff";
import DetailsShift from "../pages/Shift/DetailsShift";
import EditShift from "../pages/Shift/EditShift";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path={"/companies"} element={<Company />} />
        <Route path={"/add-company"} element={<AddCompany />} />
        <Route path={"/edit-company/:id"} element={<EditCompany />} />
        <Route path={"/view-company"} element={<DetailsCompany />} />

        <Route path={"/department"} element={<Department />} />
        <Route path={"/add-department"} element={<AddDepartment />} />
        <Route path={"/edit-department/:id"} element={<EditDepartment />} />
        <Route path={"/view-department"} element={<DetailsDepartment />} />

        <Route path={"/shift"} element={<Shift />} />
        <Route path={"/add-shifts"} element={<AddShift />} />
        <Route path={"/edit-shift/:id"} element={<EditShift />} />
        <Route path={"/view-shift"} element={<DetailsShift />} />


        <Route path={"/staffs"} element={<Staff />} />
        <Route path={"/edit-staff/:id"} element={<EditStaff />} />
        <Route path={"/view-staff"} element={<DetailsStaff />} />
        <Route path={"/add-staff"} element={<AddStaff />} />


        <Route path={"/reports"} element={<Report />} />
        <Route path={"/settings"} element={<Setting />} />
        <Route path={"/attendance"} element={<AttendaceReport />} />
        <Route path="/*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
